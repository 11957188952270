<template>
  <div class="audiovisualSelection">
    <img
      class="img"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/monthlyHotTopicsNTo2_aiJiSheng/audiovisualSelection/top.png"
    />
    <div :class="['form', { fixed: scrollTop > 80 }]">
      <van-field
        v-model="form.phone_number"
        type="tel"
        maxlength="11"
        placeholder="请输入您的手机号码"
      />
      <div class="submit" @click="onSubmit">立即订购</div>
      <div class="checked"  @click="checked = !checked">
        <img
          class="checked-icon"
          :src="`https://cdn.qiumo.net.cn/allsmarketmaterial/monthlyHotTopicsNTo2_aiJiSheng/audiovisualSelection/${
            checked ? 'checked' : 'unchecked'
          }.png`"
        />
        <span>我已阅读并同意</span>
        <span class="protocol-text" @click.stop="visibility = true">《服务协议》</span>
      </div>
    </div>
    <!-- 服务协议 -->
    <van-popup v-model="visibility" class="dialog-wrap">
      <div class="dialog-content">
        <div class="dialog-title">服务协议</div>
        <div class="dialog-text">{{ dialogContent }}</div>
        <img
          class="confirm-button"
          src="https://cdn.qiumo.net.cn/allsmarketmaterial/trialSelectionMember/promotion499/confirm.png"
          @click="visibility = false"
        />
      </div>
    </van-popup>
    <img
      class="card"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/monthlyHotTopicsNTo2_aiJiSheng/audiovisualSelection/card1.png"
    />
    <img
      class="card"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/monthlyHotTopicsNTo2_aiJiSheng/audiovisualSelection/card2.png"
    />
     <div class="info">
      【订购须知】</br>
      1、本页面仅供客户购买权益，老年及未成年人请谨慎消费，敬请知悉！</br>
      2、为了您的权益保障，请注意核实订购规则及订单详情，不要轻信陌生人通过网络、电话、短信告知的信息，谨防网络电信诈骗</br>
      【商品名称】：视听甄选会员</br>
      【计费方式】：支付宝连续包月</br>
      【资费】：首月29.9元，次月起49.9元/月</br>
      【商品权益说明】：热门会员权益*2+5元话费券*1</br>
      【会员使用说明】：视听会员权益如腾讯爱奇艺通过订购手机号登陆对应平台即可使用</br>
      【5元红包使用说明】：</br>
      ① 红包自用户领取起30天内有效，领取后发放到支付宝卡包，红包不可拆分，不得提现、无法转赠、不得为他人付款，过期自动作废，无法使用，同时也不予补发。</br>
      ② 该红包可用场景为支付宝话费充值-话费常规充值/话费代扣充值(自动充),使用支付宝选择指定银行及卡类型且单笔实际支付金额大于或者等于5.01元时可抵扣使用(实际使用范围以红包可抵扣为准)。使用红包的订单若发生退款，订单可退金额及红包可退面额以消费者的退款页面信息为准。退回的红包若在有效期内则可继续使用，且使用规则不变；退回的红包若已过有效期则无法使用，且不予补发。</br>
    </div>
    <img
      class="img"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/monthlyHotTopicsNTo2_aiJiSheng/audiovisualSelection/card3.png"
    />
  </div>
</template>

<script>
import { dialogContent } from "../trialSelectionMember/promotion499/index.js";
import { alipayPay } from "@/api/tao.js";

export default {
  data() {
    return {
      form: {
        phone_number: "",
      },
      checked: false,
      visibility: false,
      dialogContent,
    };
  },
  methods: {
    onSubmit() {
      const { phone_number } = this.form;
      if (!phone_number) return this.$toast("请输入手机号码");
      let filterPhone = /^1[3456789]\d{9}$/;
      if (!filterPhone.test(phone_number))
        return this.$toast("手机号码有误请重新输入");
      if (!this.checked)
        return this.$toast("请阅读并同意《服务协议》");
      alipayPay({
        phone_number,
        item_name: "视听甄选会员",
      }).then((res) => {
        if (res.code != 0) {
          this.$toast.fail(res.message);
        } else {
          location.href = res.sign_str
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.audiovisualSelection {
  background-color: #fdebca;
  .img {
    width: 100%;
  }

  .form {
    position: absolute;
    top: 728px;
    left: 65px;
    right: 61px;
    ::v-deep .van-cell {
      margin: 0px auto 0;
      overflow: hidden;
      box-sizing: border-box;
      padding: 0 0 0 40px;
      // width: 624px;
      height: 90px;
      background-color: #ffffff;
      border-radius: 45px;
      border: solid 1px #ffbe90;

      .van-field__body {
        height: 100%;

        input {
          height: 100%;
        }
      }

      &::after {
        border: none;
      }

      .van-field__control {
        // color: #781903;
        font-size: 30px;
        font-weight: normal;
        color: #4d2f1c;
        &::-webkit-input-placeholder {
          font-size: 30px;
          color: #c8c9cc;
        }
      }
    }

    .submit {
      margin-top: 41px;
      height: 90px;
      font-size: 32px;
      line-height: 90px;
      color: #ffffff;
      text-align: center;
      background-image: linear-gradient(
          -90deg,
          #ff7c00 0%,
          #ff6600 48%,
          #ff5000 81%
        ),
        linear-gradient(#fee2af, #fee2af);
      background-blend-mode: normal, normal;
      border-radius: 45px;
      margin-bottom: 26px;
    }

    .checked {
      font-size: 24px;
      color: #6e482c;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      line-height: 24px;

      &-icon {
        width: 24px;
	      height: 24px;
        margin-right: 8px;
      }

      .protocol-text {
        color: #ff5d00;
      }
    }
  }

  .info {
    padding: 0 40px 46px 37px;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 40px;
    letter-spacing: 1px;
    color: #4d2f1c;
  }

  .card {
    display: block;
    margin: 0 auto 37px;
    width: 680px;
  }

  .dialog-wrap {
    width: 630px;
    height: 70vh;
    background: linear-gradient(151deg, #ffffff 0%, #ffdfd9 100%);
    border-radius: 24px;
    .dialog-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px 37px;
      height: 100%;
      box-sizing: border-box;
      .dialog-title {
        font-weight: 500;
        font-size: 34px;
        color: #000000;
        margin-bottom: 30px;
        flex: none;
      }
      .dialog-text {
        font-size: 24px;
        color: #333333;
        line-height: 36px;
        white-space: pre-line;
        overflow: auto;
        flex: 1;
      }
      .confirm-button {
        width: 510px;
        height: 90px;
        margin-top: 40px;
        flex: none;
      }
    }
  }
}
</style>